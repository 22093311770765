import { useState, useEffect } from 'react';
import { useQuery, useMutation } from "react-query"
import useQueryClient from "hooks/useQueryClient";
import {
	getOrders,
	getOrder,
} from "services/requests/orders"
import { useGetAddressByCustomerId } from './addresses'
import { useGetProject, usePutProjectOrders}  from "./projects";
import {Order, serializeOrder} from "types/Order";
import {apiAxios} from "../../services/Api";

export function useGetOrders(params) {
	const defaultParams = {
		page: 1,
		itemsPerPage: 30
	}


	return useQuery(
		['orders', {...defaultParams, ...params}],
		() => getOrders({ ...defaultParams, ...params })
	)
}

export function useGetOrder(orderId: string) {
	return useQuery(
		['orders', orderId],
		() => getOrder(orderId),
		{ enabled: !!orderId }
	)
}

export function usePostOrder() {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, {localCode: string}>(order => {
			return apiAxios.post(
				'/shop/orders/ftv_create_order',
				order,
				{ headers: {'Content-Type': 'application/ld+json'} }
			).then(response => Promise.resolve( serializeOrder(response.data) ) )
		},
		{
		onSuccess: async data => {
			await Promise.all([
				queryClient.invalidateQueries(['stats']),
				queryClient.invalidateQueries(['projects', data.projectId.toString()]),
				queryClient.invalidateQueries(['orders']),
				queryClient.invalidateQueries(['project-orders'])
			])
		}
	})
}

export function usePostAndAttachOrder(projectId) {
	const { data: projectData } = useGetProject(projectId)
	const { mutateAsync: putProjectOrders } = usePutProjectOrders(projectId)
	const { mutateAsync: postOrder } = usePostOrder()

	return useMutation( () => attachOrder())

	async function attachOrder() {
		return postOrder({ localCode: 'nl_NL' }).then(async order => {
			const orderId = order.code
			const projectOrders = { orders : [...projectData.orders, `/api/v2/shop/orders/${orderId}`] }
			await putProjectOrders(projectOrders)
			return order
		})
	}
}

export const useOrderComplete: (order: Order, remarks: string, customerId: number) => {data: any, isLoading: boolean, mutateAsync: any} =
(order, remarks, customerId) => {
	const queryClient = useQueryClient()

	const [orderId, setOrderId] = useState<string>('')
	const [paymentId, setPaymentId] = useState<number>(0)

	useEffect(() => {
		if (!order) return
		setOrderId(order.code)
		setPaymentId(order.payment ? order.payment.id : 0)
	}, [order])

	const {data: addressData} = useGetAddressByCustomerId(customerId)

	return useMutation<any, unknown, Order>( () => completeOrder(), {
		onSuccess: async data => {
			await Promise.all([
				queryClient.invalidateQueries(['orders']),
				queryClient.invalidateQueries(['orders', orderId]),
				queryClient.invalidateQueries(['project-orders']),
			])
		}
	})

	async function completeOrder () {
		if ( !orderId || !customerId || !addressData)  return
		return apiAxios.put(`/shop/orders/${order.code}/ftv_complete_order`, {
			...addressData,
			notes: remarks,
			paymentMethod: '/api/v2/shop/payment-methods/invoice'
		})

	}
}
