import AudioPlayer from 'components/elements/AudioPlayer'
import AuthLogo from 'components/elements/auth/AuthLogo'
import {useThemeMedia} from '../../hooks/api/themes-media';
import React from 'react';
import {getEnv} from "../../common/EnvUtil";
import {useThemeColor} from "../../hooks/api/themes-color";
import {useLocation} from 'react-router-dom'
import WhatsAppCode from '../elements/WhatsAppCode'

export default function AuthLayout(props: any) {
    const {REACT_APP_BASE_IMAGE_URL} = getEnv()
    const themeId = parseInt(new URLSearchParams(useLocation().search).get('theme') ?? '0')

    const themeMedia = useThemeMedia(themeId)
    const themeColor = useThemeColor(themeId)
    const {data: logo, isLoading: logoLoading} = themeMedia(['logo'])
    const {data: background, isLoading: backgroundLoading} = themeMedia(['background', 'video_background'])
    const {data: backgroundColor} = themeColor("background-color")
    const {data: textColor} = themeColor("text-color")

    return (
        <>
            <div className="flex min-h-screen bg-isabelline-500"
                 style={{backgroundColor: backgroundColor.color, color: textColor.color}}>
                <div className="flex flex-col justify-center flex-1 px-6 py-12 sm:px-12 lg:flex-none">
                    <div className="w-full max-w-sm mx-auto lg:max-w-none lg:w-[500px]">
                        {logoLoading ? <></> : logo.id ? <figure>
                            <img src={`${REACT_APP_BASE_IMAGE_URL}${logo.path}`}
                                 className="max-w-45 max-h-20 mb-8 mx-auto" alt="Logo" title="Logo"/>
                        </figure> : <AuthLogo/>}
                        {props.children}
                    </div>
                </div>
                <div className="relative flex-1 hidden w-0 lg:block">
                    {!background.id &&
                        <img
                            className="absolute inset-0 object-cover w-full h-full"
                            src="https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80&sat=-100"
                            alt=""
                        />
                    }
                    {!backgroundLoading && !!background.id && background.type === 'background' &&
                        <img
                            className="absolute inset-0 object-cover w-full h-full"
                            src={`${REACT_APP_BASE_IMAGE_URL}${background.path}`}
                            alt=""
                        />
                    }
                    {!backgroundLoading && !!background.id && background.type === 'video_background' &&
                        <video className="h-screen r-0 b-0 absolute object-cover object-center" autoPlay={true}
                               muted={true} controls={false} loop={true}>
                            <source src={`${REACT_APP_BASE_IMAGE_URL}${background.path}`}/>
                        </video>
                    }
                </div>
            </div>
            <AudioPlayer/>
            <WhatsAppCode/>
        </>
    )
}
