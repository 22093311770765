import React, { useEffect, useState } from "react";
import Button from "components/elements/Button";
import Input from "components/elements/form/Input";
import Panel from "components/elements/Panel";
import { Client, clientInit } from "types/Client";
import { Contact, contactInit } from "types/Contact";
import { XCircleIcon } from "@heroicons/react/outline";
import Stack from "components/elements/stack/Stack";
import { Address, addressInit } from "types/Address";
import { useClientDTO, usePostClient } from "hooks/api/clients";
import { usePostAddress } from "hooks/api/addresses";
import { useGetCountries } from "hooks/api/countries";
import Select from "components/elements/form/Select";
import { usePostContact } from "hooks/api/contacts";
import { toast } from "react-toastify";

export interface ClientCreateProps {
	show:boolean
	onClose: () => void
}

const ClientCreate: React.FC<ClientCreateProps> = ({
	show,
	onClose
}) => {
	const [showAddClient, setShowAddClient] = useState<boolean>(false)
	const [newClient, setNewClient] = useState<Client>(clientInit)
	const [newContact, setNewContact] = useState<Contact>(contactInit)
	const [newAddress, setNewAddress] = useState<Address>(addressInit)
	const [showAddContact, setShowAddContact] = useState<boolean>(false)

	const { data: dataCountries } = useGetCountries()
	const { mutateAsync: postClient, isLoading: loading } = usePostClient()
	const { mutateAsync: postAddress, isLoading: loadingAddress } = usePostAddress()
	const { mutateAsync: postContact } = usePostContact()

	const{ clientDTO } = useClientDTO(newClient)

	useEffect(() => {
		setShowAddClient(show)
	}, [show])

	const addContact = () => {
		setNewContact(contactInit)
		setShowAddContact(true)
	}

	const onInputChangeCompany = (e) => {
		setNewClient({ ...newClient, [e.target.name]: e.target.value })
	}

	const onInputChangeAddress = (e) => {
		setNewAddress({ ...newAddress, [e.target.name]: e.target.value })
	}

	const onInputChangeContact = (e) => {
		setNewContact({ ...newContact, [e.target.name]: e.target.value })
	}

	const addContactToClient = (e) => {
		e.preventDefault()
		setNewClient({
			...newClient,
			contacts: [
				...newClient.contacts,
				newContact
			]
		})
		setShowAddContact(false)
	}

	const removeContact = (contact: Contact) => {
		setNewClient({
			...newClient,
			contacts: newClient.contacts.filter((item) => item !== contact)
		})
	}

	const closeClientCreate = () => {
		setShowAddClient(false)
		onClose()
	}


	const saveClient = (e) => {
		e.preventDefault()
		postClient(clientDTO)
		.then(resp => {
			let postAddressPromise: Promise<any> = Promise.resolve();
			if (!(newAddress.city === "" && newAddress.postcode === "" && newAddress.street === "")) {
				postAddressPromise = postAddress({
					city: newAddress.city,
					countryCode: newAddress.countryCode,
					postcode: newAddress.postcode,
					street: newAddress.street,
					firstName: newAddress.firstName,
					lastName: newAddress.lastName,
					type: newAddress.type,
					customer: resp.idPath
				})
			}
			return Promise.all([postAddressPromise, saveContacts( resp.idPath)])
		}).catch(e => {
			toast.error( `There was a problem creating the client ${e.data['hydra:description']}` );
		})
	}

	const saveContacts = async ( customerId: string ) => {
		return Promise.all(
			newClient.contacts.map( item => {
				delete item.id
				return postContact({...item, customer: customerId})
			})
		).then(() => {
			setNewClient( clientInit )
			toast.success( 'Client succesfully created' );
			closeClientCreate();
			return Promise.resolve()
		})
	}

	return (
		<>
			<Stack isShow={showAddClient} onClose={() => closeClientCreate()} title="Add client">
				<div className="px-8 mt-12">
					<h3 className="text-xl font-bold">Company details</h3>
					<p className="text-sm">Enter the company details of the client that you wish to add:</p>
				</div>
				<form onSubmit={saveClient}>
					<div className="px-8 mt-4 space-y-4">
						<Input label="Name of the company" name="company" type="text" placeholder="Type the company name" onChange={onInputChangeCompany} required/>
						<Input label="Company email" name="email" type="text" placeholder="Type the email address of the company" onChange={onInputChangeCompany} required/>
						<Input label="Address" name="street" type="text" placeholder="Type the address of the company" onChange={onInputChangeAddress} />
						<Input label="Zipcode" name="postcode" type="text" placeholder="Type the zipcode of the company" onChange={onInputChangeAddress} />
						<Input label="City" name="city" type="text" placeholder="Type the city of the company" onChange={onInputChangeAddress} />
						{<Select label="Select a country" name="countryCode" value={newAddress.countryCode} placeholder="Select a country" onChange={onInputChangeAddress}>
							{dataCountries && dataCountries.items && dataCountries.items.map( ( item ) => (
								<option key={item.code} value={item.code}>{item.name}</option>
							) )}
						</Select>}
						<Input label="Chamber of Commerce number" name="kvkNumber" type="text" placeholder="Type the KVK number of the company" onChange={onInputChangeCompany} />
						<Input label="VAT number" name="vatNumber" type="text" placeholder="Type the VAT number of the company" onChange={onInputChangeCompany} />
					</div>

					<div className="flex items-start justify-between px-8 mt-12">
						<div>
							<h3 className="text-lg font-bold">Company contacts</h3>
							<p className="text-sm">Add contacts to the company</p>
						</div>
						<div>
							<Button type="outline" onClick={addContact}>Add contact</Button>
						</div>
					</div>
					{newClient && newClient.contacts &&
						<div className="px-8 py-4 mt-4 border-t border-b bg-isabelline-600" >
							{newClient.contacts?.length === 0 && <div>
									<p>No contacts added yet. <button className="text-sm border-b border-cobalt-500" onClick={(e)=> {e.preventDefault(); addContact()}}>Click to add contact</button></p>
								</div>}

							{newClient.contacts.map((contact, index)=> (
								<Panel key={index} >
									<div className="flex items-center justify-between">
										<div>{contact.firstName} {contact.lastName}</div>
										<button onClick={() => removeContact(contact)}><XCircleIcon className="w-6 h-6 text-gray-700"/></button>
									</div>
								</Panel>
							))}
						</div>
					}

					<div className="flex px-8 my-12 space-x-4">
						<Button type="primary" submit={true} loading={loading || loadingAddress}>Save client</Button>
						<Button type="grayoutline" onClick={() => closeClientCreate()}>Cancel</Button>
					</div>
				</form>
			</Stack>

			<Stack isShow={showAddContact} onClose={() => setShowAddContact(false)} size='quarter' title="Add Contact">
				<div className="px-8 py-4 mt-4">
					<h3 className="text-xl font-bold">Contact details</h3>
					<p className="text-sm">Enter the Contact details that you wish to add:</p>
				</div>
				<form onSubmit={addContactToClient}>
					<div className="px-8 py-6 space-y-4">
						<Input label="First name" name="firstName" type="text" placeholder="Type the contact firstname like 'John'" onChange={onInputChangeContact} required />
						<Input label="Last name" name="lastName" type="text" placeholder="Type the contact lastname like 'Smith'" onChange={onInputChangeContact} required />
						<Input label="Email" name="email" type="text" placeholder="Type the email address of the contact like 'john@company.com'" onChange={onInputChangeContact} required />
						<Input label="Phone number" name="phone" type="text" placeholder="Type the phone number like '+31612345678' or '0612345678'" onChange={onInputChangeContact} optional={true} />

					</div>
					<div className="flex px-8 py-4 my-4 space-x-4">
						<Button type="primary" submit={true}>Add Contact</Button>
						<Button type="grayoutline" onClick={() => setShowAddContact(false)}>Cancel</Button>
					</div>
				</form>
			</Stack>
		</>
	)
}

export default ClientCreate