import React, {useState, useEffect, useRef} from "react";
import useStyling from "hooks/useStyling";
import { capitalizeFirstLetter } from "common/StringUtils";
import Select from "components/elements/form/Select";
import MainPage from "components/elements/page/MainPage";
import Page from "components/elements/page/Page";
import Pagination from "components/elements/Pagination";
import Voice from "components/elements/Voice";
import { EmojiSadIcon } from '@heroicons/react/outline';
import Panel from "components/elements/Panel";
import LoaderSpinning from 'components/elements/LoaderSpinning';
import { setActiveVoice } from 'hooks/useActiveVoice';
import { useGetVoices } from "hooks/api/voices";
import { useGetTaxon } from "hooks/api/taxons";
import { Voice as VoiceElement } from 'types/Voice';
import Input from "components/elements/form/Input";
import Button from "components/elements/Button";
import {FilterIcon} from "@heroicons/react/outline";


const Voices: React.FC<{
	selectableVoices?:boolean
	selectedVoices?: VoiceElement[]
	onChange?: (voices: VoiceElement[]) => void
}> = (props) => {
	const {switchClassNames} = useStyling()

	const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
	const toggleVisibility = () => {
		setFilterVisible((prevVisibility) => !prevVisibility);
	};

	const pageSize = 30;
	const [currentPage, setCurrentPage] = useState( 1 )
	const [totalItems, setTotalItems] = useState( 0 )
	const [activeFilters, setActiveFilters] = useState({})
    const [selectValues, setSelectValues] = useState({});
    const [activeCharacters, setActiveCharacters] = useState<String[]>([])
	const [search, setSearch] = useState<string>('')
	const [params, setParams] = useState<{
		'productTaxons.taxon.code': any[],
		'translations.name': string | null,
		itemsPerPage: number,
		page: number
	}>({
		'productTaxons.taxon.code': [],
		'translations.name': null,
		itemsPerPage: pageSize,
		page: currentPage
	})

	const { data: dataTaxon, isLoading: loadingTaxon } = useGetTaxon('voice')
	const [baseAggregation, setBaseAggregation] = useState<any>(undefined)
	const [filters, setFilters] = useState<any>([])
	const [character, setCharacter] = useState<any>([])

	const [voices, setVoices] = useState<VoiceElement[]>([])
	const [selectVoices, setSelectVoices] = useState<VoiceElement[]>([])
	const { data: dataFiltered, isLoading, isFetching } = useGetVoices(params)

	useEffect( () =>{
		if(dataFiltered) {
			setVoices(dataFiltered.items)
			setTotalItems(dataFiltered.totalItems)
			if (!baseAggregation) {
				setBaseAggregation(dataFiltered.aggregations)
				setActiveFilters({...activeFilters, 'Actief': 'active_active'})
			}
		}
	}, [dataFiltered])

	useEffect(() => {
		if (!dataFiltered || !dataTaxon || !baseAggregation) return
		const taxons = dataTaxon.children.map(group => {
			const options = group.code === 'voice_character' && dataFiltered.aggregations ? dataFiltered.aggregations[group.code] : baseAggregation[group.code]
			return {
				name: group.name,
				code: group.code,
				default: group.code === 'voice_active' ? 'active_active' : undefined,
				options
			}
		})
		setFilters(taxons.filter(group => group.code !== 'voice_character').sort((a, b) =>
			a.name > b.name ? 1 : a.name < b.name ? -1 : 0
		))

		const characters = taxons.find(group => group.code === 'voice_character')
		// sort options by count and name
		characters.options.sort((a, b) => a.count > b.count ? -1 : a.count < b.count ? 1 : a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
		setCharacter([characters])
	}, [dataFiltered, dataTaxon, baseAggregation])

	useEffect( () => {
		setSelectVoices( props.selectedVoices ? props.selectedVoices : [])
	}, [])

	const onFiltersChangeCredentials = (e) => {
		setActiveFilters({ ...activeFilters, [e.target.name]: e.target.value })
		setCurrentPage(1)
	}

	const onToggleCharacter = (character:String) => {
		const exists = activeCharacters.includes(character)

		if (exists) {
			setActiveCharacters( activeCharacters.filter((c) => { return c !== character }))
		} else {
			setActiveCharacters([...activeCharacters, character])
		}
		setCurrentPage(1)
	}

	const onEnterSearch = (e) => {
		if (e.key != 'Enter') return
		setSearch(e.currentTarget.value.toLowerCase())
	}

	// const onResetSearch = (e) => {
	// 	setSearch("")
	// 	setParams({...params, 'translations.name': null})
	// }

	const onResetSearch = (e) => {
		setSearch(""); // Clear the search input
		setActiveFilters({}); // Reset active filters
		setActiveCharacters([]); // Reset selected characters/tags
        setSelectValues({}); // Reset all select fields
		setCurrentPage(1); // Reset pagination to the first page
		setParams({
			'productTaxons.taxon.code': [],
			'translations.name': null,
			itemsPerPage: pageSize,
			page: 1 // Reset page to 1
		});

        // Clear input and select fields
        if (inputRef.current) {
            inputRef.current.value = ""; // Clear search input field
        }
    };

	useEffect(() =>{
		if(isFetching) return
		let filters = Object.values(activeFilters).filter(String)
		filters = filters.concat( activeCharacters)

		let searchQuery;
		if (search.length > 1) {
			searchQuery = search;
		}

		setParams( { 'productTaxons.taxon.code': filters, 'translations.name': searchQuery, itemsPerPage: pageSize, page: currentPage} )

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ activeFilters, activeCharacters, search, currentPage])


	const voiceChangeHandler = (voice:VoiceElement, selected:boolean) => {
		const voicesWithoutSelected = selectVoices.filter( ( item ) => { return item.id !== voice.id })
		if(selected){
			setSelectVoices( [...voicesWithoutSelected, voice])
		}else{
			setSelectVoices(voicesWithoutSelected)
		}
	}

	useEffect(() => {
		if ( props.onChange ) props.onChange( selectVoices)
	}, [selectVoices])

    const voicesRendering = () => (
		<>
			<Panel className="mb-8">
				{loadingTaxon ?
					<LoaderSpinning>Loading filters</LoaderSpinning> :
					<>
						<div className="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 mt-8">
							<div className="col-span-2 xl:col-span-3 2xl:col-span-4">
								<Input label="Search" onKeyDown={onEnterSearch} ref={inputRef}></Input>
							</div>
							<div className="self-center w-full lg:mt-5">
								<Button onClick={onResetSearch}>Reset</Button>
							</div>
						</div>

						<Button type={'outline'} onClick={toggleVisibility} className='mt-4 inline-flex items-center lg:hidden'><FilterIcon className="w-4 mr-1.5"/>{filterVisible ? "Hide filters" : "Show filters"}</Button>
						<div className={`${filterVisible ? '' : 'hidden'} lg:!block`}>
							<div className="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 mt-8">
								{filters.map(item => (
									<Select
                                        key={item.name}
                                        label={capitalizeFirstLetter( item.name )}
                                        name={item.name} className="max-w capitalize"
                                        defaultValue={item.default} placeholder="Type the project name"
                                        onChange={onFiltersChangeCredentials}
                                        value={activeFilters[item.name] || ""}
                                    >
										<option value="">No filter</option>
										{item.options.map((option) => (
											<option key={option.code} value={option.code}>{option.name} ({option.count})</option>
										))}
									</Select>
								))}
							</div>

							<div className="mt-8">
								<label className="block mb-2 text-sm font-medium text-gray-700">Tags</label>

								{character.map((item, index) => (
									<div key={'char-' + index} className="flex flex-wrap gap-2">
										{ item.options.map((option) => (
											<div key={option.code}
												 className=	{
													switchClassNames(activeCharacters.includes(option.code) ?
														'text-white bg-cobalt-500 border-white':
														'text-gray-500 bg-transparent hover:bg-blacks-50 hover:text-gray-700 transition-all duration-300 ease-in-out',
														'rounded-full cursor-pointer border border-gray-400 px-4 py-1 text-sm'
													)}
												 onClick={() => onToggleCharacter(option.code)}>
												{option.name}
												<span className="px-1 min-w-5.5 ml-2 bold rounded-full bg-gray-400/50 text-white text-sm inline-block text-center">
													<span className="block scale-90 relative">{option.count > 99 ? '99' : option.count}</span>
												</span>
											</div>
										))}
									</div>
								))}
							</div>
						</div>
					</>}
			</Panel>


			{isFetching ? <LoaderSpinning>Loading voices</LoaderSpinning> : totalItems > 0 ?
				<div className="text-gray-600 body-font">
					<div className="grid grid-cols-3 gap-4 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8">
						{voices.map((item, index) => (
							<div className="h-auto aspect-w-10 aspect-h-10" key={item.id}>
								<Voice voice={item} onClick={() => setActiveVoice( item )} selectable={!!props.selectableVoices} isSelected={selectVoices ? selectVoices.filter( e => e.id === item.id ).length > 0: false} onChange={(selected) => {voiceChangeHandler(item, selected)}} />
							</div>
						))}
					</div>
				</div>
				:
				<div>
					<div className="mb-6">
						<EmojiSadIcon className="w-16 h-16 mx-auto text-gray-200" />
					</div>
					<p className="max-w-lg mx-auto text-center">
						There are no voices found that match your filters.
					</p>
				</div>
			}

			<div>
				<Pagination
					className="pagination-bar"
					currentPage={currentPage}
					totalCount={totalItems}
					pageSize={pageSize}
					onPageChange={page => setCurrentPage(page)}
				/>
			</div>
		</>
	)


	if (!!props.selectableVoices){
		return (
			<Page>
				{ voicesRendering() }
			</Page>
		)
	}else {

		return (
			<MainPage title='' isLoading={isLoading}>
				{voicesRendering()}
			</MainPage>
		)
	}

}

export default Voices
