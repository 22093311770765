import useNavigation from 'hooks/useNavigation';
import Breadcrumb from '../Breadcrumb';
import FakeLoader from '../FakeProgress';

export default function MainPage(props) {
	const { current } = useNavigation()

	return (
		<div className="pb-24 main">
			<div className="w-full h-[2px] bg-transparent">
				{props.isLoading && <FakeLoader/>}
			</div>
			<div className="p-4 mb-4 sm:px-6 md:px-8">
				<Breadcrumb />
				<div className="flex justify-between">
					<h1 className="text-2xl font-semibold text-gray-900 h-8">{props.title ? props.title : current?.name}</h1>
					<div className="flex items-center gap-2">
						{props.headerActions}
					</div>
				</div>
			</div>
			<div className="p-4 sm:px-6 md:px-8">
				{props.children}
			</div>
		</div>
	)
}