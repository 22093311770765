import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import Button from 'components/elements/Button'
import MainPage from 'components/elements/page/MainPage'
import Pagination from 'components/elements/Pagination'
import Panel from 'components/elements/Panel'
import TabBar from 'components/elements/TabBar'
import ProjectRow from './project/ProjectRow'
import LoaderSpinning from 'components/elements/LoaderSpinning'
import Empty from 'components/elements/Empty'
import { useGetProjects } from 'hooks/api/projects'
import {useCurrentUserRole} from "../../../hooks/useCurrentUserRole";


export default function Projects() {
	const location = useLocation()
	const history = useHistory()
	const query = useQuery()
	const role = useCurrentUserRole()

	const pageSize = 30;
	const [currentPage, setCurrentPage] = useState( 1 )
	const [totalItems, setTotalItems] = useState( 0 )

	const projectsRoutes: any [] = [
		{
			path: '/projects',
			name:'Open'
		},
		{
			path: '/projects?state=archived',
			name: 'Archived'
		},
	]

	const { data, isLoading: loading, isFetching: fetching } = useGetProjects( {
		state: query.get( 'state' ) ? query.get( 'state' ) : 'open',
		itemsPerPage: pageSize,
		page: currentPage,
	});

	useEffect( () => {
		if ( data ) setTotalItems( data.totalItems )
	}, [data] )

	const addProject = () => {
		history.push('/projects/create')
	}

	const openProject = (id) =>{
		history.push(`/projects/${id}/overview`)
	}

	useEffect( () => {
		setCurrentPage(1)
	}, [location] )

	const actions = useMemo(() => {
		console.log(role)
		return (
			role === "reseller" ? <Button onClick={() => addProject()}>Add project</Button> : <></>
		)
	}, [role])

	return (
		<MainPage title='' isLoading={loading} headerActions={actions} >
			<TabBar routes={projectsRoutes}  />
			<Panel className="px-0 py-0">
				<div className="flex flex-col">
					<div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
							<div className="overflow-hidden">
								{loading || fetching ? <LoaderSpinning className="p-8">Loading projects...</LoaderSpinning> :
									(data && data.items.length > 0 ?
										<table className="min-w-full divide-y divide-gray-200">
											<thead className="border-b border-gray-200">
												<tr>
													<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Project name</th>
													<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Client name</th>
													<th scope="col" className="px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Status</th>
													<th scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Created at</th>
													<th	scope="col" className="hidden px-6 pt-5 pb-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase md:table-cell">Updated at</th>
												</tr>
											</thead>
											<tbody className="space-y-4">
												{data && data.items.map((project, index) => (
													<ProjectRow key={project.id} index={index} project={project} onClick={openProject}/>
												))}
											</tbody>
										</table> : <Empty className="p-8">Create a project</Empty>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</Panel>
			<Pagination
				className="pagination-bar"
				currentPage={currentPage}
				totalCount={totalItems}
				pageSize={pageSize}
				onPageChange={page => setCurrentPage( page )}
			/>
		</MainPage>
	)
}
