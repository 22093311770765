import { Address, addressInit, serializeAddress } from "types/Address";

export interface Customer {
	id?: number
	idPath?: string
	firstName: string
	lastName: string
	email: string
	password: string
	phoneNumber: string
	defaultAddress?: Address | { id: number }
	subscribedToNewsletter: boolean
	company: string
	kvkNumber: string
	vatNumber: string
	projects: string[]
	theme: string
	themeId: number
	reseller?: Customer
	groupCode: string
}

export interface CustomerDTO {
	company: string
	kvkNumber: string
	vatNumber: string
}

export const customerInit =  {
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	phoneNumber: '',
	subscribedToNewsletter: false,
	company: '',
	kvkNumber: '',
	vatNumber: '',
	projects: [],
	theme: '',
	themeId: 0,
	groupCode: ''
}

export const customerDTOInit = {
	company: '',
	kvkNumber: '',
	vatNumber: '',
}

export function serializeCustomer(data): Customer {
	if (!data) return customerInit;

	let defaultAddress: Address | { id: number } = addressInit

	if (typeof(data.defaultAddress) !== 'string') {
		defaultAddress = serializeAddress(data.defaultAddress)
	} else {
		defaultAddress = {id: data.defaultAddress.replace('/api/v2/shop/addresses/', '')}
	}

	return {
		idPath: data['@id'],
		firstName: data.firstName,
		lastName: data.lastName,
		email: data.email,
		password: data.password,
		phoneNumber: data.phoneNumber,
		subscribedToNewsletter: data.subscribedToNewsletter,
		company: data.company ? data.company : '',
		kvkNumber: data.kvkNumber ? data.kvkNumber : '',
		vatNumber: data.vatNumber ? data.vatNumber : '',
		projects: data.projects,
		theme: data.theme,
		reseller: !data.reseller ? data.reseller : serializeCustomer(data.reseller),
		themeId: data.theme ? +data.theme.replace('/api/v2/shop/themes/', '') : 0,
		groupCode: data.group ? data.group.replace('/api/v2/admin/customer-groups/', '') : '',
		defaultAddress,
	}
}