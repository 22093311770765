import useToken from "./useToken";
import {useGetCustomerByUrl} from "./api/customers";

const useCurrentUserRole = () => {
	const { getUser } = useToken();
	const { data: customer } = useGetCustomerByUrl(getUser() ? getUser().customer : "");

	return customer?.groupCode ?? '';
}

export { useCurrentUserRole };
